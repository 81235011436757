import React from 'react';
import { Navigate } from "react-router-dom";
import { userContext } from './context';


export default class Product extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.isSignedIn = this.isSignedIn.bind(this);
  }

  isSignedIn () {
    return localStorage.getItem('isSignedIn') === 'true' ? true : false;
  }



  render() {
    return (
      <div>
        {!this.isSignedIn() && <Navigate to="/login" />}
        <div className="product">Congratulations!!</div>
      </div>
    )
  }
}

Product.contextType = userContext;