import React from 'react';
import { Link } from "react-router-dom";

export default function NoPage(){
  return(
    <div className="notFound">
       Oops! the url you are trying to access is not valid. 
       <Link to="/">Home</Link>
    </div>
  )
}