import React from 'react';
import { Navigate, useSearchParams, Link, useNavigate } from "react-router-dom";
import { userContext } from './context';
import { redirect } from "react-router-dom";
const url = require('url');


export default function Auth() {
  let [searchParams] = useSearchParams();
  const oobCode = searchParams.get('oobCode');

  console.log(searchParams, '!!!!');

  const mode = searchParams.get('mode');
  const apiKey = searchParams.get('apiKey');

  const continueUrl = searchParams.get('continueUrl');
  const queryObject = url.parse(continueUrl).query;

  console.log(oobCode, mode, continueUrl, queryObject);



  return (
    <div>
      {continueUrl && mode === 'resetPassword' && <Navigate to={"/reset-password?oobCode=" + oobCode + '&' + queryObject} />}
      <div className="redirect">Click <Link to='/reset-password'>here</Link> if you don't get redirected to the Reset Password page in 3 seconds.</div>
    </div>
  )
}

Auth.contextType = userContext;