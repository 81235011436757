import React from 'react';
import { Outlet, Link, Navigate } from "react-router-dom";
import { userContext } from './context';
import { signinWithGoogle, auth, sendPasswordResetEmail } from './firebase';


export default class Login extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      passwordReset: false
    }
    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();

    this.signinWithGoogle = signinWithGoogle.bind(this);
    this.passwordResetEmail = this.passwordResetEmail.bind(this);

    this.domain = process.env.NODE_ENV === 'development' ? 'localhost:3000' : 'https://www.reactclass.com'

    this.showForgotPasswordDialog = this.showForgotPasswordDialog.bind(this);
  }

  passwordResetEmail(e) {
    const passwordResetConfig = {
      url: this.domain + '/reset-password?email=' + this.emailRef.current.value
    }

    e.preventDefault();
    sendPasswordResetEmail(auth, this.emailRef.current.value, passwordResetConfig)
      .then((result) => {
        console.log('result of reset email ', result);
        this.setState({ passwordReset: true })
        alert('Password reset link sent! Please make sure to check you spam folder.');
        return;
      })
      .catch(error => {
        console.log(error.message)
        console.log(error)
      })
  }

  showForgotPasswordDialog(e) {
    e.preventDefault();
    this.setState({ passwordReset: true });
  }

  render() {

    const login = <><div className='emailLogin'>
      {this.context.user.isSignedIn && <Navigate to="/" />}
      <div className='inputWrap'>
        <label for="txtEmail">email</label>
        <input ref={this.emailRef} type="text" name="txtEmail" id="txtEmail"></input>
      </div>
      <div className='inputWrap'>
        <label for="txtPassword">password</label>
        <input ref={this.passwordRef} type="text" name="txtPassword" id="txtPassword"></input>
      </div>
      <button className='loginBttn'>Login</button>
      <div className='passwordResetOption'><a href='#' onClick={this.showForgotPasswordDialog}>Forgot Password?</a></div>
      <div className='signUpOption'>Don't have an account? <Link to='/signup'>Sign Up</Link></div>
    </div>

      <div className='providerSignIn'>
        <button className='providerBttn' onClick={this.signinWithGoogle}>Sign In with Google</button>
      </div></>

    const forgotPassword = <div className='inputWrap'>
      <label for="txtEmail">email</label>
      <input ref={this.emailRef} type="text" name="txtEmail" id="txtEmail"></input>
      <button className='loginBttn' onClick={this.passwordResetEmail}>Get Password Reset Link</button>
    </div>



    return (
      <div className="loginWrap">
        {this.state.passwordReset ? forgotPassword : login}
      </div>
    )
  }
}

Login.contextType = userContext;