import React from 'react';
import { useSearchParams, Link } from "react-router-dom";
import { userContext } from './context';
import { confirmPasswordReset } from 'firebase/auth';


export default function ResetPassword(){
  const emailRef = React.createRef();
  const passwordRef = React.createRef();
  const confirmPasswordRef = React.createRef();

  let [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const code = searchParams.get('oobCode');

  function doConfirmPasswordReset() {
    let password =passwordRef.current.value
    if (!code || !password) {
      return;
    }
    confirmPasswordReset(code, password)
    .then(result => {
      console.log('success');
    })
    .catch(err => {
      console.log(err.message);
    });
  }

  return (
    <div className="loginWrap">
      <div className='emailLogin'>
        <div className='inputWrap'>
          <label htmlFor="txtEmail">email</label>
          <input ref={emailRef} type="text" placeholder={email} name="txtEmail" id="txtEmail"></input>
        </div>
        <div className='inputWrap'>
          <label htmlFor="txtPassword">password</label>
          <input ref={passwordRef} type="text" name="txtPassword" id="txtPassword"></input>
        </div>
        <div className='inputWrap'>
          <label htmlFor="txtConfirmPassword">confirm password</label>
          <input ref={confirmPasswordRef} type="text" name="txtConfirmPassword" id="txtConfirmPassword"></input>
        </div>
        <button className='resetPasswordBttn' onClick={doConfirmPasswordReset}>Reset Password</button>
        <div className='backToLogin'>Go back to<Link to='/login'>Login</Link></div>
      </div>
    </div>
  )
}

ResetPassword.contextType = userContext;