import React from 'react';
import { Navigate, Link } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, signinWithGoogle } from './firebase'
import { userContext } from './context';


export default class SignUp extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.usernameRef = React.createRef();
    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
    this.confirmPasswordRef = React.createRef();

    this.createUserWithEmail = this.createUserWithEmail.bind(this);
    this.signinWithGoogle = signinWithGoogle.bind(this);
  }

  componentDidMount() {
    // this.unregisterAuthObserver = app.auth().onAuthStateChanged((user) => {
    //   this.setState({isSignedIn: !!user});
    // });
  }

  createUserWithEmail() {
    let email = this.emailRef.current.value;
    let password = this.passwordRef.current.value;
    let confirmPassword = this.confirmPasswordRef.current.value;

    if (email && password && confirmPassword
      && password === confirmPassword) {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          console.log(user);
          this.context.updateUser(true, user.email, null, user);
          // ...
        })
        .catch((error) => {
          // TODO: Error codes -- display appropriate errors based on the error codes.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
          // ..
        });
    } else return false;
  }

  successDialog() {
    // let signupWrap = 
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    // this.unregisterAuthObserver();
  }

  render() {
    return (
      <div className="signupWrap">
        {this.context.user.isSignedIn && <Navigate to="/" />}
        <div className='emailSignup'>
          <div className='inputWrap'>
            <label htmlFor="txtEmail">email</label>
            <input ref={this.emailRef} type="text" name="txtEmail" id="txtEmail"></input>
          </div>
          <div className='inputWrap'>
            <label htmlFor="txtUsername">username</label>
            <input ref={this.usernameRef} type="text" name="txtUsername" id="txtUsername"></input>
          </div>
          <div className='inputWrap'>
            <label htmlFor="txtPassword">password</label>
            <input ref={this.passwordRef} type="text" name="txtPassword" id="txtPassword"></input>
          </div>
          <div className='inputWrap'>
            <label htmlFor="txtConfirmPassword">confirm password</label>
            <input ref={this.confirmPasswordRef} type="text" name="txtConfirmPassword" id="txtConfirmPassword"></input>
          </div>
          <button className='signupBttn' onClick={this.createUserWithEmail}>Sign Up</button>
          <div className='signInOption'>Already have an account? <Link to='/login'>Login</Link></div>
        </div>

        <div className='providerSignIn'>
          <button className='providerBttn' onClick={this.signinWithGoogle}>Sign In with Google</button>
        </div>
      </div>
    )
  }
}

SignUp.contextType = userContext;