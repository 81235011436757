import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, 
  connectAuthEmulator, signInWithPopup, sendPasswordResetEmail, confirmPasswordReset } from "firebase/auth";

const googleProvider = new GoogleAuthProvider();

const firebaseConfig = {
  apiKey: "AIzaSyCSTOVe1LIIsD9JisBgem2KxlI_aoJ-v2o",
  authDomain: "reactclasscom.firebaseapp.com",
  projectId: "reactclasscom",
  storageBucket: "reactclasscom.appspot.com",
  messagingSenderId: "705855711466",
  appId: "1:705855711466:web:90939d73874211ccb40f93",
  measurementId: "G-60P5K4MVYS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);
const auth = getAuth(app);

if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(auth, "http://localhost:9099");
}

function signinWithGoogle() {
  signInWithPopup(auth, googleProvider)
    .then((result) => {
      console.log('in signinwithpopup ', result, result.user);
      // This gives you a Google Access Token. You can use it to access the Google API.
      const token = result.user.accessToken;
      // The signed-in user info.
      const email = result.user.email;

      console.log('result ', email, token, result)

      this.context.updateUser(true, email, token, result.user);
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      // The AuthCredential type that was used.

      console.log(errorCode, errorMessage, '!!!! ');
      const credential = googleProvider.credentialFromError(error);
      // ...
    });
}

function passwordReset() {
  confirmPasswordReset(auth, this.code, this.password)
  .then(confirmed => {
    return;
  })
  .catch(error => {
    console.log(error.message);
  })
}

export {
  auth,
  app,
  signinWithGoogle,
  passwordReset,
  sendPasswordResetEmail,
  confirmPasswordReset
  // db,
}