import React, { Component } from 'react';
import { Outlet, Link } from "react-router-dom";
import { userContext } from './context';

export default class Layout extends Component{
  constructor(props, context) {
    super(props,context);
    this.signout = this.signout.bind(this);
  }

  signout () {
    this.context.updateUser(false);
  }
  
  render(){
    return(
      <div className="mainApp">
        <header className="App-header">
          { !this.context.user.isSignedIn && !this.context.user.user ? <><Link to="/login">Login</Link><Link to="/signup">SignUp</Link></>
          :  <button className='bttn logout' onClick={this.signout}>Logout</button> }
        </header>
        <div className="body">
          <Outlet />
        </div>
      </div>
    )
  }
}

Layout.contextType = userContext;