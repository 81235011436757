import React from 'react';
import Login from './Login';
import SignUp from './SignUp';
import Layout from './Layout';
import Home from './Home';
import Product from './Product';
import NoPage from './NoPage';
import Auth from './Auth';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { userContext } from './context';
import ResetPassword from './ResetPassword';

const userAuthKey = 'isSignedIn';
const userTokenKey = 'userToken';
const userEmailKey = 'userEmail';
const userKey = 'user';

export default class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      user: {
        isSignedIn: localStorage.getItem(userAuthKey) === 'true' ? true : false,
        email: localStorage.getItem(userEmailKey) || undefined,
        token: localStorage.getItem(userTokenKey) || undefined,
        user: (localStorage.getItem(userKey) && typeof localStorage.getItem(userKey) === 'object') ? JSON.parse(localStorage.getItem(userKey)) : undefined
      }
    }
    this.updateUser = this.updateUser.bind(this);
    this.requireAuth = this.requireAuth.bind(this);
  }

  updateUser(isSignedIn, email, token, user) {
    console.log('!!!! in update user ', isSignedIn, email, token, user);
    let _state = this.state;
    _state.user.isSignedIn = isSignedIn;
    _state.user["email"] = email || undefined;
    _state.user["token"] = token || undefined;
    _state.user["user"] = user || undefined;
    this.setState(_state);
    localStorage.setItem(userAuthKey, isSignedIn);
    localStorage.setItem(userTokenKey, token);
    localStorage.setItem(userEmailKey, email);
    localStorage.setItem(userKey, JSON.stringify(user));
  }

  requireAuth (nextState, replace, callback) {
    let isSignedIn = localStorage.getItem(userAuthKey) === 'true' ? true : false
    if (!isSignedIn) replace('/login')
    return callback()
  }

  render() {
    return (
      <userContext.Provider value={{ user: this.state.user, updateUser: this.updateUser }}>
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="signup" element={<SignUp />} />
                <Route path="login" element={<Login />} />
                <Route path="product" element={<Product />}/>
                <Route path="auth" element={<Auth />}/>
                <Route path="reset-password" element={<ResetPassword />}/>
                <Route path="*" element={<NoPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
      </userContext.Provider>
    );
  }
}


// 1. Implement Logout
// 2. Implement Google Sign In