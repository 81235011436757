import React from 'react';
import { Outlet, Link } from "react-router-dom";
import { userContext } from './context';

export default class Banner extends React.Component {

  // constructor(props,context){
  //   super(props,context)
  // }

  /**
   * @inheritDoc
   */
  componentDidMount() {
    // this.unregisterAuthObserver = app.auth().onAuthStateChanged((user) => {
    //   this.setState({isSignedIn: !!user});
    // });
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    // this.unregisterAuthObserver();
  }

  render() {
    const signUpLink = <Link to="/signup"><button className="bttnBuy bttn">Get Started</button></Link>;
    const checkoutLink = <a href='https://checkout.stripe.com/c/pay/cs_live_b1YOsdwy79zby9xEcjVhXWQgVvi3PNI6wu6ecUNs36i3K4BOhS1wJys8N2#fidkdWxOYHwnPyd1blppbHNgWj0wfXJkQ2hmUVJPUnxXY3RrNTNcVX10ZycpJ3VpbGtuQH11anZgYUxhJz8nMWJyNDFhNTJUZ11rM2pQYVRUJ3gl'><button className="bttnBuy bttn">Buy Now</button></a>;

    console.log('!!!! ', this.context.user)
    return (
      <div className="banner">
        <div className="title">Title!</div>

        <div className="desc">Subtitle</div>

        <div className="desc">For just $27</div>
          {console.log(this.context.user.isSignedIn)}
          { this.context.user.isSignedIn && checkoutLink }
          { !this.context.user.isSignedIn && signUpLink }
      </div>
    )
  }
}

Banner.contextType = userContext;